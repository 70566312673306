import React, { useState } from "react";
import { hooks } from "botframework-webchat-component";
import PropTypes from "prop-types";
import "./ResponseTag.css";
import { LiaThumbsUp, LiaThumbsDown } from "react-icons/lia";
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";

const { useSendEvent } = hooks;

const BotActivityDecorator = ({ activity, disabledId, setDisabledId }) => {
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);
  const sendEvent = useSendEvent();

  const handleUpButton = () => {
    sendEvent("ResponseFeedbackSubmitted", {
      ResponseId: activity.value.ResponseTagId,
      ResponseUseful: true,
    });
    setThumbsDown(false);
    setThumbsUp(!thumbsUp);
    if (!disabledId.includes(activity.id)) {
      const id = [...disabledId, activity.id];
      setDisabledId(id);
    }
  };

  const handleDownButton = () => {
    sendEvent("ResponseFeedbackSubmitted", {
      ResponseId: activity.value.ResponseTagId,
      ResponseUseful: false,
    });
    setThumbsUp(false);
    setThumbsDown(!thumbsDown);
    if (!disabledId.includes(activity.id)) {
      const id = [...disabledId, activity.id];
      setDisabledId(id);
    }
  };
  return (
    <div className="cb-botActivityDecorator">
      <div className="cb-botActivityDecorator__buttonBar">
        <p>Was this content helpful?</p>
        {/* <div> */}
          <button
            className="cb-botActivityDecorator__button"
            onClick={handleUpButton}
            disabled={disabledId.includes(activity.id)}
            style={{ color: thumbsUp ? "darkgrey" : "gray" }}>
            {!thumbsUp ? <LiaThumbsUp /> : <IoMdThumbsUp />}
          </button>
          <button
            className="cb-botActivityDecorator__button"
            onClick={handleDownButton}
            disabled={disabledId.includes(activity.id)}
            style={{ color: thumbsDown ? "darkgrey" : "gray" }}>
            {thumbsDown ? <IoMdThumbsDown /> : <LiaThumbsDown />}
          </button>
        {/* </div> */}
      </div>
    </div>
  );
};

BotActivityDecorator.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({
      ResponseTagId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  disabledId: PropTypes.array.isRequired,
  setDisabledId: PropTypes.func.isRequired,
};

export default BotActivityDecorator;
