/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React from "react"; 

const NormalSendBox = ({ inputRef, onKeyDown, extBtn, typing }) =>{ 

  // console.warn("12",typing)
  return( 
  <>
    <div className="cb-auto-complete-sendbox-default">
      <div className="cb-auto-complete-sendbox">
        <input
          type="text"
          ref={inputRef}
          aria-labelledby="type your message"
          className="cb-auto-complete-sendbox-input"
          onKeyDown={onKeyDown}
          placeholder={typing?"Typing...":"Type your message"}
          disabled={typing?true:false}
        />
      </div>
      {extBtn.name ? (
        <div>
          <button type="button" onClick={extBtn.onClick} className="cb-auto-complete-send-btn">
            {extBtn.name}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  </>
)};

NormalSendBox.defaultProps = {
  extBtn: undefined,
};

NormalSendBox.propTypes = {
  inputRef: PropTypes.shape({}).isRequired,
  onKeyDown: PropTypes.func.isRequired,
  extBtn: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default NormalSendBox;
