import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const openChatbot = (config) => {
  if (!window.lmChatbotStatus) {
    const root = createRoot(document.getElementById("LMChatbotContainer"));

    if (!config) {
      const chatbotConfig = window.sessionStorage.getItem("LMChatbotConfig");
      config = chatbotConfig ? JSON.parse(chatbotConfig) : null;
    }

    if (config) {
      const titles = document.getElementsByTagName("title");
      titles[ 0 ].innerHTML = `Lilly ${config.productName} ${config.user} Chat Bot`;
    } else {
      const configElement = document.getElementById("config");
      config = JSON.parse(configElement.textContent);
    }

    root.render(
      <React.StrictMode>
        <App config={config} />
      </React.StrictMode>
    );

    if (process.env.NODE_ENV !== "production") {
      const ReactDOM = require("react-dom");
      const axe = require("@axe-core/react");

      const config = {
        rules: [
          {
            id: "skip-link",
            enabled: false,
          },
        ],
        disableDeduplicate: true,
      };

      axe(React, ReactDOM, 1000, config);
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.log);
    }
    window.lmChatbotStatus = "Started";
    document.dispatchEvent(new CustomEvent("LMChatbotStarted"));
  } else {
    const chatbot = document.getElementById("LMChatbotContainer");
    chatbot.style = "display: block";
    window.lmChatbotStatus = "Opened";
    document.dispatchEvent(new CustomEvent("LMChatbotOpened"));
  }
  document.body.style.overflow = "hidden";
};

if (window.lmChatbot) {
  window.lmChatbot = { ...window.lmChatbot, open: openChatbot };
} else {
  openChatbot();
}
