/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import qs from "qs";
import React, { useMemo, useCallback, useState } from "react";
import { createStore, Components } from "botframework-webchat";
import { useDeviceSelectors } from "react-device-detect";

import BotActivityDecorator from "./ResponseTag";
import Header from "./Header";
import WarningBox from "./WarningBox";
import WebChat from "./WebChat";

import "./MenuBasedWebChat.css";

// import lillylogo from "../assets/Lilly_bot.svg";

import updateIn from "simple-update-in";
// import BotActivity from 'botframework-webchat';
import SuggestedActions from "./SuggestedActions";

const MenuBasedWebChat = ({ config }) => {
  const [ token, setToken ] = useState();
  const [ userID, setUserID ] = useState();
  const [ disabledId, setDisabledId ] = useState([]);
  const [ suggestions, setSuggestions ] = useState({
    values: [],
    stopWords: [],
    fallBack: "",
  });
  const [ enableAutoCompleteSendBox, setEnableAutoCompleteSendBox ] = useState({
    state: false,
  });
  const [ disableAutoCompleteSendBox, setDisableAutoCompleteSendBox ] = useState({
    state: false,
  });
  const [ enableNormalSendBox, setEnableNormalSendBox ] = useState({
    state: false,
  });
  const [ typing, setTyping ] = useState({
    state: false,
  });

  const [ reviewPrompt, setReviewPrompt ] = useState(false);
  const [ showWarningBanner, setShowWarningBanner ] = useState(true);

  const store = useMemo(
    () =>
      createStore(
        {},
        ({ dispatch }) =>
          (next) =>
            (action) => {
              const queryParams = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });
              const source = queryParams.referrer || window.location.host;
              const currentURL = window.location.href;
              // console.log("86 action.type", action.type);
              if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
                setTyping(false);
                const [ selectors, deviceDetails ] = useDeviceSelectors();
                const { isDesktop } = selectors;
                const info = {
                  timeOpened: new Date().getTime(),
                  timezone: new Date().getTimezoneOffset() / 60,
                  browserLanguage: navigator.language,
                  browserName: deviceDetails.browser.name,
                  browserVersion: deviceDetails.browser.version,
                  browserMajor: deviceDetails.browser.major,
                  browserEngine: deviceDetails.engine.name,
                  browserEngineVersion: deviceDetails.engine.version,
                  deviceVendor: deviceDetails.device.vendor,
                  deviceModel: deviceDetails.device.model,
                  deviceType: isDesktop
                    ? "Desktop"
                    : deviceDetails.device?.type.charAt(0).toUpperCase() +
                    deviceDetails.device?.type.slice(1),
                  deviceOrientation: screen?.orientation?.type,
                  osName: deviceDetails.os.name,
                  osVersion: deviceDetails.os.version,
                  engine: deviceDetails.engine.name,
                  engineVersion: deviceDetails.engine.version,
                  ua: deviceDetails.ua,
                  sizeScreenW: window.screen.width,
                  sizeScreenH: window.screen.height,
                  sizeInW: window.innerWidth,
                  sizeInH: window.innerHeight,
                  sizeAvailW: window.screen.availWidth,
                  sizeAvailH: window.screen.availHeight,
                  referrer: source,
                  currentUrl: currentURL
                };
                // console.log(info);

                dispatch({
                  type: "WEB_CHAT/SEND_EVENT",
                  payload: {
                    // name: "webchat/join",
                    name: "UserDeviceDetails",
                    value: info,
                  },
                });
              } else if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
                if (action.payload.activity.type === "typing") {
                  // console.log("126", typing, action.payload.activity)
                  setTyping(true);
                  // console.log("128", typing);
                  setTimeout(() => {
                    setTyping(false);
                  }, 3000);
                }
                if (action.payload.activity.name === "setQuestions") {
                  setSuggestions(action.payload.activity.value);
                } else if (action.payload.activity.name === "setSendBoxState") {
                  setEnableAutoCompleteSendBox(action.payload.activity.value);
                } else if (
                  action.payload.activity.name === "setAutoCompleteDisableState"
                ) {
                  setDisableAutoCompleteSendBox(action.payload.activity.value);
                } else if (
                  action.payload.activity.name === "enableNormalSendBox"
                ) {
                  setEnableNormalSendBox(action.payload.activity.value);
                } else if (
                  action?.payload?.activity?.name === "showReviewPrompt"
                ) {
                  setReviewPrompt(true);
                } else if (
                  action?.payload?.activity?.name === "hideWarningBanner"
                ) {
                  setShowWarningBanner(!action.payload.activity.value);
                } else if (action.payload.activity.name === "echoBack") {
                  dispatch({
                    type: "WEB_CHAT/SEND_POST_BACK",
                    payload: {
                      value: action.payload.activity.value,
                      text: action.payload.activity.value.text,
                    },
                  });
                } else if (action.payload.activity.name === "redirectToURL") {
                  window.location.href = action.payload.activity.value.url;
                } else if (action.payload.activity.name === "loadIframe") {
                  const modal = document.getElementById("cb-modal");
                  const iframe = document.getElementById("cb-iframe");
                  modal.style = "display: flex";
                  iframe.src = action.payload.activity.value.url;
                }
              } else if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
                action = updateIn(
                  action,
                  [ "payload", "activity", "channelData", "urls"],
                  () => ({ referrer: source, currentUrl: currentURL })
                );
                setTyping(false);
              }

              return next(action);
            }
        //typingIndicatorMiddleware()
      ),
    []
  );

  const { AdaptiveCardContent } = Components;

  const attachmentMiddleware =
    () =>
      (next) =>
        ({ activity, attachment, ...others }) => {
          if (
            attachment.contentType === "application/vnd.microsoft.card.adaptive"
          ) {
            if (attachment.content.actions) {
              const submitAction = attachment.content.actions.find(
                (action) => action.type === "Action.Submit"
              );
              // console.log("submitAction-->", submitAction);
              if (submitAction) {
                const { activities } = store.getState();
                const messageActivities = activities.filter(
                  (msgActivity) => msgActivity.type === "message"
                );
                const lastMessage = messageActivities.pop();
                const recentBotMessage = lastMessage === activity;

                // console.log("activity-->", activity);
                // console.log("lastMessage-->", lastMessage);
                // console.log("attachment-->", attachment);
                // console.log("matching", recentBotMessage);
                return (
                  <AdaptiveCardContent
                    actionPerformedClassName="card__action--performed"
                    content={attachment.content}
                    disabled={!recentBotMessage}
                  />
                );
              }
            }
          }

          return next({ activity, attachment, ...others });
        };

  const activityMiddleware =
    () =>
      (next) =>
        (...setupArgs) => {

          const [ card ] = setupArgs;
          const { activities } = store.getState();
          const messageActivities = activities.filter(
            (msgActivity) => msgActivity.type === "message"
          );
          const lastMessage = messageActivities.pop();
          const recentBotMessage = lastMessage === card.activity;

          if (card.activity.from.role === "user" && recentBotMessage) {
            setShowWarningBanner(true);
          }

          if (card?.activity?.name === "enableFeedbackPrompt") {
            return () => (
              <BotActivityDecorator
                activity={card.activity}
                disabledId={disabledId}
                setDisabledId={setDisabledId}></BotActivityDecorator>
            );
          } else if (
            card.activity.from.role === "bot" &&
            card?.activity?.suggestedActions &&
            recentBotMessage
          ) {
            return (...renderArgs) => (
              <div>
                {card.activity?.text ? next(card)(...renderArgs) : next(card)}
                <SuggestedActions role="region" config={config} />
              </div>
            );
          } else {
            return next(card);
          }
        };

  const styleOptions = {
    hideSendBox: true,
    rootHeight: "90%",
    hideUploadButton: true,
    // Bubble
    bubbleBackground: config.bubbleBackground || "#F2F2F2",
    bubbleBorderColor: config.bubbleBorderColor || "#F2F2F2",
    bubbleBorderRadius: "5px",
    bubbleBorderStyle: "None",
    bubbleBorderWidth: 0,
    bubbleFromUserBackground: config.bubbleFromUserBackground || "#D52B1E",
    bubbleFromUserBorderColor: config.bubbleFromUserBorderColor || "#D52B1E",
    bubbleFromUserBorderRadius: "5px",
    bubbleFromUserBorderStyle: "None",
    bubbleFromUserBorderWidth: 0,
    bubbleFromUserNubOffset: 0, // Either a positive/negative number, or "bottom"
    bubbleFromUserNubSize: undefined, // Or a number. 0 means a sharp corner.
    bubbleFromUserTextColor: "White",
    bubbleImageHeight: 400,
    bubbleMaxWidth: 480, // screen width = 600px
    bubbleMinHeight: 40,
    bubbleMinWidth: 400, // min screen width = 300px, Microsoft Edge requires 372px (https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13621468/)
    // bubbleNubOffset: 0, // Either a positive/negative number, or "bottom"
    // bubbleNubSize: undefined, // Or a number. 0 means a sharp corner.
    bubbleTextColor: "Black",
    // Avatar
    avatarBorderRadius: "50%",
    avatarSize: 40,
    botAvatarBackgroundColor: config.botAvatarBackgroundColor || "#FFFFFF", // defaults to accent color
    // botAvatarImage: lillylogo, // Or a string of URL. Can be a data URI or blob.
    botAvatarInitials: "Lilly Assist", // Or a string. Empty strings means it has avatar but not initials inside.
    // userAvatarBackgroundColor: undefined, // defaults to accent color
    // userAvatarImage: lillylogo, // Or a string of URL. Can be a data URI or blob.
    // userAvatarInitials: "test", // Or a string. Empty strings means it has avatar but not initials inside.
    // showAvatarInGroup: 'status', // Or 'sender' or true (on every activity).
    // transcriptActivityVisualKeyboardIndicatorColor: DEFAULT_SUBTLE,
    transcriptActivityVisualKeyboardIndicatorStyle: "none",
    transcriptActivityVisualKeyboardIndicatorWidth: 1,
    transcriptVisualKeyboardIndicatorColor: undefined,
    transcriptVisualKeyboardIndicatorStyle: undefined,
    transcriptVisualKeyboardIndicatorWidth: undefined,
    // Suggested Actions
    suggestedActionLayout: "stacked",
    // Markdown
    markdownExternalLinkIconImage: undefined,
  };

  const handleFetchToken = useCallback(async () => {
    if (!token) {
      const botTokenElement = config.botToken;
      const userIdElement = config.userId;
      setToken(botTokenElement);
      setUserID(userIdElement);
    }
  }, [ setToken, token ]);

  return (
    <>
      <div className="cb-App-chatwidget">
        <Header config={config} />
        <WebChat
          className="react-web-chat"
          reviewPrompt={reviewPrompt}
          onFetchToken={handleFetchToken}
          attachmentMiddleware={attachmentMiddleware}
          activityMiddleware={activityMiddleware}
          suggestions={suggestions}
          store={store}
          // styleSet={styleSet}
          styleOptions={styleOptions}
          token={token}
          userID={userID}
          disableAutoCompleteSendBox={disableAutoCompleteSendBox}
          enableAutoCompleteSendBox={enableAutoCompleteSendBox}
          enableNormalSendBox={enableNormalSendBox}
          typing={typing}
          config={config}
          // onClick={handleLinkClick}
          sendTypingIndicator={true}
        />
        <WarningBox config={config} showWarningBanner={showWarningBanner} />
      </div>
    </>
  );
};

export default MenuBasedWebChat;
