/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { createDirectLine, Components } from "botframework-webchat";
import PropTypes from "prop-types";
import AutoComplete from "./AutoComplete";
import lillylogo from "../assets/LillyLogo_Red.jpeg";
import "./WebChat.css";
import ReviewBox from "./ReviewTag";
import URLClicks from "./URLClicks";

let messageText = "Please wait we are connecting...";
let msgClass;



const WebChat = ({
  className,
  reviewPrompt,
  onFetchToken,
  suggestions,
  styleOptions,
  store,
  token,
  userID,
  attachmentMiddleware,
  enableAutoCompleteSendBox,
  disableAutoCompleteSendBox,
  enableNormalSendBox,
  activityMiddleware,
  typing,
  config
}) => {
  const directLine = useMemo(() => createDirectLine({ token }), [ token ]);
  const { Composer, BasicWebChat } = Components;
  const [carddata,setCardDta]=useState(false);


/**
 * The `cardActionMiddleware` is the middleware which help to get click event of Adaptive Card.
 * @returns The `cardActionMiddleware` function returns the `next(action)` call, which passes the
 * action to the next middleware in the chain.
 */
  const cardActionMiddleware = () => next => action => {
    if (action?.cardAction?.type === 'openUrl') {
      setCardDta(action)
    }
    return next(action);
  };
  const unsetcardData=()=>{
    setCardDta(false)
  };

  useEffect(() => {

    onFetchToken();
    const token = config.botToken;

    if (token === "ERROR" || token === "") {
      messageText = "Unauthorised Access: Please Contact Support"
      msgClass = "cb-error";
    }
  }, [ onFetchToken ]);

  return token && userID ? (
    <>
      <Composer
        directLine={directLine}
        userID={userID}
        store={store}
        styleOptions={styleOptions}
        attachmentMiddleware={attachmentMiddleware}
        activityMiddleware={activityMiddleware}
        cardActionMiddleware={cardActionMiddleware}
      >
        <BasicWebChat className={`${className || ""} web-chat`} />
        <AutoComplete
          role="region"
          aria-label="questions search box"
          suggestions={suggestions}
          enableAutoCompleteSendBox={enableAutoCompleteSendBox}
          enableNormalSendBox={enableNormalSendBox}
          disableAutoCompleteSendBox={disableAutoCompleteSendBox}
          typing={typing}
        />
        <ReviewBox
          reviewPrompt={reviewPrompt}
          config={config}
        />
        <URLClicks config={config}  cardData={carddata} unsetcardData={unsetcardData}/>
      </Composer>
    </>
  ) : (
    <div className={`${className || ""} cb-connect-spinner`}>
      <div className="cb-content">
        <div className="cb-icon">
          <img src={lillylogo} alt="Lilly" className="cb-App-logo" />
        </div>
        <p className={msgClass}>{messageText}</p>
      </div>
    </div>
  );
};

WebChat.defaultProps = {
  className: "",
  styleOptions: {},
};

WebChat.defaultProps = {
  token: "",
  userID: "",
};

WebChat.propTypes = {
  className: PropTypes.string,
  onFetchToken: PropTypes.func.isRequired,
  suggestions: PropTypes.exact({
    values: PropTypes.array.isRequired,
    stopWords: PropTypes.array.isRequired,
    fallBack: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styleOptions: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired,
  token: PropTypes.string,
  userID: PropTypes.string,
  attachmentMiddleware: PropTypes.func.isRequired,
  enableAutoCompleteSendBox: PropTypes.exact({
    state: PropTypes.bool.isRequired,
  }).isRequired,
  disableAutoCompleteSendBox: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
  enableNormalSendBox: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      action: PropTypes.string,
    }),
    button: PropTypes.shape({
      name: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

export default WebChat;