import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css'; // Import the styles
import PropTypes from "prop-types";

const ImageEnlarge = ({ imageUrl, imageAlt, onReturn }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setModalIsOpen(true);
    }
  }, [imageUrl]);

  return (
    <div>
      <Modal open={modalIsOpen} onClose={onReturn} center>
        <div>
          <img
            src={imageUrl}
            alt={imageAlt}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </div>
      </Modal>
    </div>
  );
};

ImageEnlarge.propTypes = {
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  onReturn: PropTypes.func.isRequired
};

export default ImageEnlarge;
