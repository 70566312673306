/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { hooks } from "botframework-webchat-component";
import React from "react";

import CardActionButton from "./CardActionButton";
import "./SuggestedActions.css";

const { useSuggestedActions } = hooks;

// Web Chat cleaned up the suggestedActions for us.
// If the last activity is from the bot and contains "suggestedActions",
// Web Chat will send it to us thru "suggestedActions".
const SuggestedActions = ({ config }) => {
  const suggestedActions = useSuggestedActions();
  // console.log(suggestedActions);

  const suggestedActionsTestVariable = config.verticalAlignment;

  var root = document.querySelector(":root");

  root.style.setProperty(
    "--cb-border",
    config.suggestedButtonsBorder || "#d52b20"
  );
  root.style.setProperty(
    "--cb-backgroundColor",
    config.suggestedButtonBackgroundColor || "#d52b20"
  );
  return suggestedActionsTestVariable ? (
    <div className="suggested-actions__carousel carousel-right-alignment">
      {suggestedActions[0].map((cardAction, index) => (
        <CardActionButton key={index.toString()} cardAction={cardAction} />
      ))}
    </div>
  ) : (
    <div className="suggested-actions__carousel">
      {suggestedActions[0].map((cardAction, index) => (
        <CardActionButton key={index.toString()} cardAction={cardAction} />
      ))}
    </div>
  );
};

export default SuggestedActions;
