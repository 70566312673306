import React, { useCallback, useState } from "react";
import { hooks } from "botframework-webchat-component";
import PropTypes from "prop-types";
import Interstitial from "./Interstitial";

const { useSendEvent } = hooks;

const OpenUrlButton = ({ cardAction: { title, value } }) => {

  const [popUp, setPopUp] = useState(false);

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  const handleContinuePopUp = () => {
    window.open(value, "_blank");
    setPopUp(false);
  }

  const sendEvent = useSendEvent();
  const handleClick = useCallback(() => {
    window.open(value, "_blank");
    setPopUp(false)
    sendEvent("OpenURLClicks", value);
  }, [value]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        type="button"
      >
        {title}
      </button>

      {popUp && (
        <Interstitial
          onContinue={handleContinuePopUp}
          onReturn={handleClosePopUp}
        />
      )}
    </React.Fragment>
  );
};

OpenUrlButton.propTypes = {
  cardAction: PropTypes.exact({
    type: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default OpenUrlButton;
