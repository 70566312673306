/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import { LiveAnnouncer } from "react-aria-live";
import "./App.css";

// import Header from "./web-chat/Header";
// import WarningBox from "./web-chat/WarningBox";
import MenuBasedWebChat from "./web-chat/MenuBasedWebChat";


function App({ config }) {
  //Adding the background color to the adaptive card button
  var root = document.querySelector(":root");
  root.style.setProperty(
    "--cb-cardBackgroundColor",
    config.bubbleFromUserBackground || "#008a26"
  );
  root.style.setProperty(
    "--cb-headerTextColor",
    config.headerTextColor || "#FFFFFF"
  );
  root.style.setProperty(
    "--cb-logoHeight",
    config.logoHeight || "5em"
  );
  root.style.setProperty(
    '--cb-headerHeight',
    config.headerHeight || '7em'
  );

  return (
    <div className="cb-App" >

      {/* <Header config= {config}/> */}
      {/* <section className="App-body"> */}
      {/* <main id="main" className="App-chatwidget-container"> */}
      <main id="main">
        {/* <div className="App-chatwidget"> */}
        <LiveAnnouncer>
          <MenuBasedWebChat config={config} />
        </LiveAnnouncer>
        {/* <WarningBox config= {config}/> */}
        {/* </div> */}
      </main>
      {/* </section> */}
    </div>
  );
}

export default App;
