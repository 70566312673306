import React, { useState } from 'react';
import '../App.css';
import { hooks } from "botframework-webchat-component";
import PropTypes from "prop-types";
import { AiFillStar } from 'react-icons/ai';
import { AiOutlineStar } from 'react-icons/ai';


const { useSendEvent } = hooks;

const ReviewBox = ({reviewPrompt, config}) => {
    const sendEvent = useSendEvent();

    if (reviewPrompt) {
        sendEvent("BotFeedbackPrompted", true)
        const stars = [1, 2, 3, 4, 5];
        const [activeStar, setActiveStar] = useState(0);
        const [hoveredStar, setHoveredStar] = useState(0);
        const [disabled, setDisabled] = useState(false);

        const handleStarHover = (star) => {
            if (!disabled) {
                setHoveredStar(star);
            }
        };
        const handleStarClick = (star) => {
            if (!disabled) {
                setActiveStar(star === activeStar ? 0 : star);
                sendEvent("BotFeedbackSubmitted", star);
            }
            setHoveredStar(0);
            setDisabled(true);
        };

        return (
            <div className="cb-wrap" style={{ backgroundColor: config.bubbleFromUserBackground }}>
                {!disabled && (
                    <p className="cb-review-title">
                        Please rate your experience with the Lilly Assist chatbot today.
                    </p>
                )}
                <div className="cb-stars">
                    {stars.map((star) => (
                        <label
                            key={star}
                            className="cb-rate"
                            onMouseEnter={() => handleStarHover(star)}
                            onMouseLeave={() => handleStarHover(0)}
                            onClick={() => handleStarClick(star)}>
                            {star <= activeStar || star <= hoveredStar ? (
                                <AiFillStar className="cb-star" />
                            ) : (
                                <AiOutlineStar className="cb-star" />
                            )}
                        </label>
                    ))}
                    {disabled && (
                        <p className="cb-review-title">Thank you for rating.</p>
                    )}
                </div>
            </div>
        );
    }
    return null;
};

ReviewBox.propTypes = {
    reviewPrompt: PropTypes.any,
    config:PropTypes.any
  };

export default ReviewBox;