/* eslint-disable arrow-body-style */
import React from "react";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import ListBox from "./ListBox";

const ComboBox = ({
  isActive,
  description,
  inputRef,
  options,
  defaultOptions,
  onClick,
  cursor,
  disabled,
  onChange,
  onKeyDown,
}) => {
  // if (isActive && description.active) {
  //   announce(description.text);
  // }

  return (
    <>
      <div className={`${isActive ? "cb-auto-complete-sendbox-overlay" : "cb-auto-complete-sendbox-default"}`}>
        <div className="cb-auto-complete-container">
          <div className="cb-auto-complete-sendbox">
            <input
              type="text"
              id="combobox"
              ref={inputRef}
              onClick={onClick}
              placeholder={disabled.text || "Type your question"}
              className="cb-auto-complete-sendbox-input"
              disabled={disabled.state}
              onChange={onChange}
              onKeyDown={onKeyDown}
              role="combobox"
              aria-expanded={isActive}
              aria-controls="suggestions-list"
              aria-autocomplete="list"
              aria-activedescendant={cursor}
            />
          </div>
          {isActive && description.active ? (
            <>
              <LiveMessage message={description.text} aria-live="polite" clearOnUnmount="true" />
              <span className="cb-auto-complete-info">{description.text}</span>
            </>
          ) : (
            ""
          )}
          <div className="cb-auto-complete-suggestions-container">
            {isActive ? <ListBox options={options} defaultOptions={defaultOptions} cursor={cursor} /> : ""}
          </div>
        </div>
      </div>
    </>
  );
};

const ValuePropTypes = PropTypes.exact({
  text: PropTypes.string.isRequired,
  hightLightTxtStartIndx: PropTypes.number,
  highlightTxtLen: PropTypes.number,
  key: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
});

ComboBox.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.exact({ current: PropTypes.any })]).isRequired,
  isActive: PropTypes.bool.isRequired,
  description: PropTypes.shape({
    text: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: ValuePropTypes,
    })
  ).isRequired,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: ValuePropTypes,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  cursor: PropTypes.string.isRequired,
  disabled: PropTypes.exact({
    state: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
};

export default ComboBox;
