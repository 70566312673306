/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types"
import './Interstitial.css'

const Interstitial = ({ onContinue, onReturn, config }) => {
    const currentDomain = window.location.hostname;

    return (
        <div className="cb-behind_pop-up">
            <div className='cb-pop-up'>
                <br />
                <strong className="cb-interstitial-heading">You are now leaving {currentDomain}</strong>
                <br />
                <br />
                <span className="cb-popUp_content">The link you clicked on will take you away from this site.<br/><br/>Click <strong>{"\"Continue\""}</strong> to leave the site or <strong>{"\"Go Back\""}</strong> to close this window and return to {currentDomain}.</span>
                <div className="buttons">
                    <br />
                    <br />
                    <button onClick={onReturn} className="cb-popUpBtn cb-returnBtn">GO BACK</button>
                    <button onClick={onContinue} className="cb-popUpBtn cb-continueBtn" style={{ backgroundColor: config.interstitialContinueBackground || 'rgb(229, 30, 38)', borderColor: config.interstitialContinueBorder } || 'rgb(229, 30, 38)'}>CONTINUE</button>
                </div>
            </div>
        </div>
    );
};

Interstitial.propTypes = {
    onContinue: PropTypes.func.isRequired,
    onReturn: PropTypes.func.isRequired,
};

export default Interstitial;
