/* eslint-disable react/prop-types */
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import "../App.css";
import lillyLogo from "../assets/LillyLogo.png";

const closeWidgetHandler = () => {
  const chatbot = document.getElementById("LMChatbotContainer");
  chatbot.style = "display: none";
  window.lmChatbotStatus = "Closed";
  document.body.style.removeProperty("overflow");
  const titles = document.getElementsByTagName("title");
  titles[ 0 ].innerHTML = window.lmChatbotWebsiteHeader;
  document.dispatchEvent(new CustomEvent("LMChatbotClosed"));
};

const Header = ({ config }) => {
  return (
    <header
      className="cb-App-header"
      role="banner"
      style={{ backgroundColor: config.headerBackgroundColor || "#d52b1e" }}>
      <div className="cb-App-row cb-App-align-center">
        {config.productLogoUrl ? (
          <div className="cb-lilly-logo">
            <p className="cb-logo-text">Lilly Assist</p>
            <span className="cb-subtext">Chatbot</span>
          </div>
        ) : (
          <img src={lillyLogo} alt="Lilly" className="cb-App-logo" />
        )}
      </div>

      {config.productLogoUrl ? (
        <div className="cb-App-light-heading product-App-light-heading cb-App-col cb-App-align-center">
          <img
            src={config.productLogoUrl}
            alt={`${config.productName} Logo`}
            className="cb-App-logo"
          />
        </div>
      ) : (
        <div className="cb-App-light-heading">
          <span className="cb-productName">{config.productName}</span>
        </div>
      )}
      <div className="cb-widget-close-btn-container">
        <button
          className="cb-widget-close-btn"
          onClick={closeWidgetHandler}
          title="Close Chat Bot">
          <AiOutlineClose />
        </button>
      </div>
    </header>
  );
};

export default Header;
