/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { hooks } from "botframework-webchat-component";
import Interstitial from "./Interstitial";
import ImageEnlarge from "./imageEnlarge"
import axios from 'axios'
import { useDeviceSelectors } from "react-device-detect";

const URLClicks = ({ config, cardData,unsetcardData }) => {
    const [popUp, setPopUp] = useState(false);
    const [clickedLink, setClickedLink] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [imageAlt, setImageAlt] = useState("");

    const { useSendEvent } = hooks;
    const sendEvent = useSendEvent();

   
    if (cardData?.cardAction?.type === 'openUrl') {        
        sendEvent("OpenURLClicks", cardData?.cardAction?.value);
        unsetcardData()
    }

    const handleLinkClick = async (event) => {
        if (event.target.tagName === "A") {
            event.preventDefault();
            // checking if the element clicked is an anchor
            const linkUrl = event.target.href;
            const externalLinks = config.extLinks;
            const secureLinks = config.secureLinks;
            const jwt = window.sessionStorage.getItem("LMChatbotJWT");

            //identifying browser type
            const [selectors] = useDeviceSelectors();
            const { isSafari } = selectors;

            if (secureLinks && secureLinks?.filter((link) => linkUrl.startsWith(link)).length) {
                try {
                    const response = await axios.get(`${config.apiURL}/api/linktoken`,
                        {
                            headers: {
                                Authorization: jwt,
                            }
                        });
                    const { token } = response.data;
                    if (isSafari) {
                        const url = new URL(linkUrl);
                        url.searchParams.set("auth", token);
                        window.open(url, '_self');
                        //window.open(`${linkUrl}?auth=${token} `, '_self');
                        sendEvent("OpenURLClicks", linkUrl);
                    } else {
                        const url = new URL(linkUrl);
                        url.searchParams.set("auth", token);
                        window.open(url, '_blank');
                       //window.open(`${linkUrl}?auth=${token} `, '_blank');

                        sendEvent("OpenURLClicks", linkUrl);
                    }
                } catch (error) {
                    console.error(error);
                }
            } else if (externalLinks && externalLinks?.includes(linkUrl)) {
                setClickedLink(linkUrl);
                setPopUp(true);
            } else {
                window.open(linkUrl, "__blank");
                sendEvent("OpenURLClicks", linkUrl);
            }
        } else if (event?.target?.tagName === "IMG") {
            setImageUrl(event.target.src);
            setImageAlt(event.target.alt);
        }
    };

    const handleClearUrl = () => {
        setImageUrl("");
        setImageAlt("");
    }

    const handlePopupContinue = () => {
        setPopUp(false);
        // Opening the clicked link in a new tab/window
        window.open(clickedLink, "_blank");
        sendEvent("OpenURLClicks", clickedLink);
    };

    const handlePopupReturn = () => {
        setPopUp(false);
        setClickedLink("");
    };

    useEffect(() => {
        const webChatElement = document.getElementById("LMChatbotContainer");
        webChatElement.addEventListener("click", handleLinkClick);

        return () => {
            webChatElement.removeEventListener("click", handleLinkClick);
        };
    });

    return (<>{
        popUp && (
            <Interstitial
                onContinue={handlePopupContinue}
                onReturn={handlePopupReturn}
                config={config}
            />
        )
    }
        {
            imageUrl && (
                <ImageEnlarge
                    imageUrl={imageUrl}
                    imageAlt={imageAlt}
                    onReturn={handleClearUrl}
                />
            )
        }</>);
}

export default URLClicks