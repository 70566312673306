/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";

import "./WarningBox.css";

function WarningBox({ config, showWarningBanner }) {
    const [ warningsState, setWarningsState ] = useState({});
    const [ warningVisible, setWarningVisible ] = useState(false);
    const noOfWarnings = config.warnings.length;

    useEffect(() => {
        if (config.warning) {
            const initialState = {};
            config.warnings.forEach((_, index) => {
                initialState[ index ] = {
                    active: false,
                    state: 'closed'
                };
            });
            setWarningsState(initialState);
        }
    }, [ config.warning, config.warnings ]);

    useEffect(() => {
        let isVisible = false;
        for (let key in warningsState) {
            isVisible = (isVisible || warningsState[ key ].active);
        }
        setWarningVisible(isVisible);
    }, [ warningsState ]);

    const warningColor = `${config.bannerColor || config.suggestedButtonBackgroundColor}`;
    const styles = {
        icon: { color: warningColor },
        header: {
            closed: { borderTop: `3px solid ${warningColor}` },
            open: { borderTop: `3px solid ${warningColor}`, borderBottom: `3px solid ${warningColor}`, boxShadow: "#21212133 0px 3px 8px" }
        },
        content: {
            open: { display: "block" }
        },
        warningContainer: {
            visible: {height: warningVisible ? '100%' : '33%', opacity:'1'},
            hidden: {height: `0%`, opacity:`0`}
        }
    }

    const toggleBanner = (index) => {
        let { active, state } = warningsState[ index ];
        state = (state === 'closed' ? 'open' : 'closed');
        active = !active;
        setWarningsState({ ...warningsState, [ index ]: { active, state } });
    }

    const Warning = ({ content, id, lastIndex }) => {
        const { state, active } = warningsState[ id ] || {};
        const warningContentStyles = { display: id !== lastIndex ? 'none' : 'block', ...styles.content[ state ] };

        return (<div className="cb-warning" style={{ height: active ? "" : "auto" }}>
            <div className="cb-warning-header" style={styles.header[ state ]} onClick={() => toggleBanner(id)} >
                <div className="cb-warning-title">{content.title}</div>
                <span>
                    {active
                        ? <FaCircleMinus style={styles.icon} className="cb-warning-icon" />
                        : <FaCirclePlus style={styles.icon} className="cb-warning-icon" />
                    }
                </span>
            </div>
            <div className="cb-warning-content" style={warningContentStyles}>
                <ReactMarkdown>{content.warning}</ReactMarkdown>
            </div>
        </div >);
    }

    return config.warning ? (
        <div className="cb-warnings-container" style={
            showWarningBanner ? styles.warningContainer.visible : styles.warningContainer.hidden
          }
        >
          {config.warnings.map((content, index) => (
            <Warning key={index} content={content} id={index} lastIndex={noOfWarnings - 1}
            />
          ))}
        </div>
      ) : null;
}

export default WarningBox;
